import PageLayout from './components/page-layout';

// Individual pages
import IndexPage from './pages/landing-page';
import addaccount from './pages/addacc';
import Splash from './components/splash-loader/index';
import MaintenancePage from './components/maintenance-layout/index';
var auth = true;
function loadSpinner() {
    let $splashDiv = document.getElementById('splash');
    if (!$splashDiv) {
        $splashDiv = document.createElement('div');
        $splashDiv.setAttribute('id', 'splash');
        const $root = document.body.querySelector('#root');
        $root.appendChild($splashDiv);
    }
    m.render($splashDiv, m(Splash));
}
function hideSpinner() {
    let $splashDiv = document.getElementById('splash');
    if ($splashDiv) {
        m.render($splashDiv, null);
    }
}
function checkauth() {
    const pass = localStorage.getItem('pass');
    if (
        pass == 'Totobingo1967' ||
        pass == 'Totobingo1967!' ||
        pass == 'Tiffany2017*' ||
        pass == 'Bassword123' ||
        pass == 'MakeLotsOfMoney' ||
        pass == 'KAOS2024!' ||
        pass == 'Emharnomis@1' ||
        pass == 'Scaves88' ||
        pass == '@lex2024!' ||
        pass == 'Rodney1234*&^' ||
        pass == 'KAOS_Control8699*'
    ) {
        return true;
    } else {
        return false;
    }
}
const Routes = {
    '/login': {
        render(vnode) {
            return m(Splash);
        },
    },
    '/index': {
        /* onmatch() {
            // Show Loader until the promise has been resolved or rejected.
            loadSpinner();
            return new Promise((resolve /*, reject ) => {
              
                    resolve();
                
            }).catch((/* e  ) => {
                // In case of server error we can show the maintenance page.
                return MaintenancePage;
            });
        },*/
        render(vnode) {
            if (!checkauth()) {
                //If onmatch returns a component or a promise that resolves to a component, comes here.
                return m(Splash);
            }
            return m(PageLayout, m(IndexPage));
        },
    },
    '/add': {
        render(vnode) {
            return m(PageLayout, m(addaccount));
        },
    },
};

const DefaultRoute = '/index';

export { Routes, DefaultRoute };
